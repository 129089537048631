import { forwardRef } from 'react';
import { Badge, Avatar } from '@mui/material';
import { CustomAvatarProps } from './types';

const getCharAtName = (name: string) => name && name.charAt(0).toUpperCase();

const CustomAvatar = forwardRef<HTMLDivElement, CustomAvatarProps>(
  ({ name = '', BadgeProps, children, sx, ...other }, ref) => {
    const charAtName = getCharAtName(name);

    const renderContent = (
      <Avatar
        imgProps={{ referrerPolicy: 'no-referrer' }}
        ref={ref}
        sx={sx}
        {...other}
      >
        {name && charAtName}
        {children}
      </Avatar>
    );

    return BadgeProps ? (
      <Badge
        overlap="circular"
        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
        {...BadgeProps}
      >
        {renderContent}
      </Badge>
    ) : (
      renderContent
    );
  }
);

export default CustomAvatar;
