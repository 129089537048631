import { CustomAvatar } from '@core/ui/components/custom-avatar';
import { CustomFile } from '../types';

type Props = {
  file: CustomFile | string | null;
};

export default function AvatarPreview({ file }: Props) {
  if (!file) {
    return null;
  }

  const imgUrl = typeof file === 'string' ? file : file.preview;

  return (
    <CustomAvatar
      src={imgUrl}
      alt={imgUrl}
      name={imgUrl}
      sx={{
        width: 'calc(100% - 16px)',
        height: 'calc(100% - 16px)',
      }}
    />
  );
}
