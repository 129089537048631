import { Stack, Box } from '@mui/material';
import * as path from 'path';
import Video from '@core/ui/components/Video';
import { hideScrollbarY } from '@core/utils/cssStyles';
import Image from '../../image';
import { CustomFile } from '../types';

type Props = {
  file: CustomFile | string | null;
};

export default function SingleFilePreview({ file }: Props) {
  if (!file) {
    return null;
  }

  let isVideo = false;
  let url = '';
  if (typeof file === 'string') {
    url = file;
    const ext = path.extname(url);
    isVideo = ext === '.mp4';
  } else {
    url = file.preview || '';
    isVideo = file.type.includes('video');
  }

  const content = isVideo ? (
    <Box
      sx={{
        width: 1,
        objectFit: 'cover',
        display: 'block',
        ...hideScrollbarY,
        position: 'relative',
      }}
    >
      <Video playsInline autoPlay muted controls url={url} />
    </Box>
  ) : (
    <Image alt="file preview" src={url} />
  );

  return (
    <Stack
      sx={{
        top: 8,
        left: 8,
        zIndex: 8,
        borderRadius: 1,
        position: 'absolute',
        width: 'calc(100% - 16px)',
        height: 'calc(100% - 16px)',
      }}
    >
      {content}
    </Stack>
  );
}
