import { useState, useEffect } from 'react';
import { Box } from '@mui/material';

type Props = {
  url: string;
  playsInline?: boolean;
  muted?: boolean;
  controls?: boolean;
  autoPlay?: boolean;
  loop?: boolean;
  freeform?: boolean;
  onTimeUpdate?: (time: number) => void;
};

export default function Video({
  url,
  playsInline,
  muted,
  controls,
  autoPlay,
  loop,
  freeform,
  onTimeUpdate,
}: Props) {
  const [internalUrl, setInternalUrl] = useState(url);

  useEffect(() => {
    setInternalUrl(`${url}#t=0.0001`);
  }, [url]);

  let videoStyle: any = {
    objectFit: 'cover',
  };
  if (!freeform) {
    videoStyle = {
      position: 'absolute',
      top: 0,
      left: 0,
    };
  }
  const video = (
    <video
      loop={loop}
      playsInline={playsInline}
      muted={muted}
      controls={controls}
      autoPlay={autoPlay}
      src={`${internalUrl}#t=0.0001`}
      width="100%"
      height="100%"
      style={videoStyle}
      onTimeUpdate={(event) => {
        onTimeUpdate ? onTimeUpdate(event.currentTarget.currentTime) : null;
      }}
    >
      <source src={`${internalUrl}#t=0.0001`} />
    </video>
  );

  if (!freeform) {
    return (
      <Box
        sx={{
          position: 'relative',
          paddingTop: '56.25%',
        }}
      >
        {video}
      </Box>
    );
  }

  return video;
}
